/* css for all pages */
input{
  direction: rtl;
}
.center-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.links{
  text-decoration: none;
  color: #4e8784;
  font-weight: bold;
}
.links:hover{
  text-decoration: underline;
}
.invalid-input-mes{
  color: red;
  font-size: small;
  margin-right: 4vh;
  float:right;
}
/* /css for all pages/ */


/* css for home page */
#home-body{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:hsl(185, 12%, 82%);
}
#homebg, #guest-homebg{
  width: 100%;
  box-shadow: 5px 10px 15px hsl(0, 0%, 48%);
  border-radius: 0 0 5% 5%;
}
.homebtn-box{
  margin: 3%;
  margin-top: 30%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  width: 125px;
  height: 120px;
  padding: 15px;
  border-radius: 8%;
  border: 2px white;
  direction: rtl;
}
.guest-homebtn-box{
  margin: 3%;
  margin-top: 10%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  width: 140px;
  height: 120px;
  padding: 15px;
  border-radius: 8%;
  border: 2px white;
  direction: rtl;
}
.homebtn-row{
  margin: 10px;
  margin-top: 15%;
}
.homebtn-icons{
  color: #4e8784;
}
.homebtn-title{
  margin: 0;
  margin-bottom: 5px;
  color: darkslategrey;
  font-size: large;
}
.small-title{
  font-size: medium;
}
.homebtn-disc{
  color: gray;
  font-size: small;
}
.small-dics{
  font-size: x-small;
}
/* /css for home page/ */


/* css for side pages */
.page-background{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom left,#5585b5, transparent 40%,  #4e8784);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #4e8784;
  overflow: scroll;
}
.form-contener{
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 10px;
    margin-top: 15vh;
}
#applicant-background{
  position: relative;
}
.page-title{
  background-image: linear-gradient(to bottom left,#4e8784, transparent 40%,#7aa6d3);
  background-color: #4e8784;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.form-body{
  margin-top: 3vh;
}
.input-box{
  width:80%;
  height: 5vh;
  border-radius: 5px;
  border: solid 2px lightgrey;
}
.input-box::placeholder{
  color: #4e8784;
  font-size: large;
  padding-right: 10px;
}
#info-checkbox{
  margin: 3vh 3vh 0 3vh;
  text-align: right;
  display: block;
}
.form-checkbox:checked{
  background-color: lightgrey;
  color: lightgrey;
}
.info-label{
  font-size: small;
}
.info-text{
  color: #666464;
}
.form-submitbtn{
  background-color: transparent;
  color: #4e8784;
  border: 3px solid #4e8784;
  width: 70%;
  height: 5vh;
  display: block; 
  margin:  auto;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 3vh;
  font-size: large;
  font-weight: bold;
  transition: background-color 0.5s ease, color 0.5s ease, transform 0.5s ease;
}
.form-submitbtn:hover {
  background-color: #4e8784;
  color: #f7f7f7;
  box-shadow: none;
}
.form-links{
  text-align: right;
  margin: 2vh;
  display: inline;
}
.input-label{
  text-align:right;
  margin-right:10%;
  margin-top:2vh;
  margin-bottom:1vh;
  color:#4e8784;
  font-size: large;
}
/* /css for side pages/ */

/* css for drawer */
.drawer-header{
  height: 20vh;
  padding: 3vh 2vh ;
  align-items: center;
  justify-content: center;
  display:flex ;
  margin-bottom: 20px;
}
.logo{
  -webkit-filter: drop-shadow(5px 5px 5px #666464);
  filter: drop-shadow(5px 5px 5px #666464);
  height: 100%;
}
#line{
  width:80%;
  height: 2px;
  border-radius:50%;
  background-color:#4e8784;
}
#drawer-footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
#settings{
  position: absolute;
  bottom: 0;
}
.list-item{
  direction: rtl;
  margin-bottom: 10px;
}
.list-item:hover{
  transition: .3s;
  background-color: hsl(185, 12%, 82%);
}
.primary-text .MuiListItemText-primary {
  font-size:25px; 
  color: darkslategrey;
}
.drawer-icon{
  color: darkslategrey;
}
/* /css for drawer/ */


/* css for app bar */
#appBar-box{
  box-shadow: 5px 15px 20px hsl(185, 12%, 82%);
}
/* /css for app bar/ */


/* css for modal*/
.modal-bg{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: transparent; */
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    overflow: scroll;
}
.modal-container{
  background-color: hsl(185, 12%, 82%);
  width:75%;
  border-radius: 5px;
  box-shadow: 5px 10px 15px hsl(0, 0%, 29%);
}
.modal-header{
  margin-top: 5px;
  width: 100%;
  height: 30px;
}
.modal-body{
  margin-top: 15px;
  /* height: 80vh; */
  width: 100%;
}
.modal-primary-text .MuiListItemText-primary{
  color:rgb(92, 92, 92);
  float: right;
  font-size: large;
}
.modal-icon{
  transform: scale(1.5) scaleX(-1);
}


.modal-name{
  color:rgb(92, 92, 92);
  float: right;
  padding-right: 15px;
}
.modal-list-items{
  direction: rtl;
}
.modal-list-items:hover{
  transition: .3s;
  background-color: hsl(185, 12%, 82%);
}
.modal-btn{
  --s: 0.1em;   /* the thickness of the line */
  --c: #4e8784; /* the color */
  color: #0000;
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,darkslategrey 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
  background-color: transparent;
  border: none;
  font-size: large;
  margin: 10px;
}
.modal-btn:hover {--_p: 100%}
/* /css for modal/ */


/* css for forms */

.input-group {
  position: relative;
  display: flex; 
  justify-content: flex-end;
  margin-right: 3vw;
  margin-top: 6vw;
}

.input {
 border: solid 1.5px grey;
 border-radius: 5px;
 background: none;
 padding: 1rem;
 font-size: 1rem;
 color: rgb(36, 36, 36);
 transition: border 150ms cubic-bezier(0.4,0,0.2,1);
 margin-bottom:15px ;
 width: 80%;
 height: 10px;
}

.user-label {
 /* position: absolute; */
 position: absolute;
 right: 15px; 
 color: #4e8784;
 pointer-events: none;
 transform: translateY(1rem);
 transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus, .input:valid {
 outline: none;
 border: 1.5px solid #4e8784;
}

.input:focus ~ label, .input:valid ~ label {
 transform: translateY(-50%) scale(0.8);
 background-color:hsl(185, 12%, 82%);
 padding: 0 .2em;
 color: #4e8784;
}
.modal-submitbtn{
  background-color: #4e8784;
  border: none;
  border-radius:10px ;
  width: 80%;
  height: 40px;
  color: whitesmoke;
  font-size: large;
  margin: 8vw;
  margin-top: 15px;
  margin-bottom: 20px;
  
}
.modal-submitbtn:hover {
  background: linear-gradient(to top,#4e8784,#689694);
  animation: button-particles 1s ease-in-out infinite;
  transform: translateY(-2px);
}
.modal-submitbtn:active {
  transform: scale(0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.form-label{
  color: #4e8784;
  margin: 10px;
  font-size: medium;
  font-weight: normal;
  align-self:end;
}

#profile-form{
  flex-direction: column;
}
.form-input{
  border: solid 2px lightgrey;
  border-radius: 8px  ;
  width: 80%;
  height: 30px;
  background-color: hsl(0, 0%, 85%);
}
.radio-input{
  margin:0 15px 15px;
}
.radio-label{
  color: darkslategray;
  font-size: large;
}
.fullapp{
  width: 60vw;
}

/* /css for forms/ */

/* css for requests menu */
#requests-modal{
  position: fixed;
  top:20%;
}
#requests-menu{
  overflow:scroll;
}
#request-box{
  margin-top:15px;
  padding-bottom: 5px;
  background-color: hsl(186, 7%, 71%);
  direction: rtl;
  width: 90%;
  
}
#request-box:hover {
  background: hsl(187, 7%, 74%);
  animation: button-particles 1s ease-in-out infinite;
  transform: translateY(-2px);
}
#request-header h3{
margin-top:0 ;
padding-top: 10px;
}
#request-header{
  background: linear-gradient(#4e8784,#689694);
  height: 40px;
  color: white;
  /* margin-top: -20px; */
  margin-bottom: 5px;
}
/* /css for requests menu/ */

@media screen and (min-width: 767px){
  
  /* target AppBar icons */
  .MuiAppBar-root .MuiSvgIcon-root {
    width: 5vh;
    height: 5vh;
  }

  /* Target Drawer icons */
  .MuiDrawer-root .MuiSvgIcon-root {
    width: 4vh;
    height: 4vh;
  }
  /* drawer */
  .primary-text .MuiListItemText-primary{
    font-size: large;
  }
  /* home */
  #homebg{
    width: 45%;
    position: relative;
    left: 12vw;
    border-radius: 5%;
  }
  #guest-homebg{
    width: 45%;
    position: relative;
    /* left: 12vw; */
    border-radius: 5%;
  }
  #home-image{
    margin-top: 2vh;
  }
  .homebtn-box{
    margin-top: 2%;
    left: 12vw;
    position: relative;
  }
  .guest-homebtn{
    width: 150px;
  }
  .homebtn-row{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 3%;
  }
  /* app bar */
  #page-name{
    margin-left: 28%;
  }
  /* modal form */
  .input-group{
    margin-top: 3vh;
  }
  .modal-submitbtn{
    margin: 6vh;
  margin-top: 15px;
  margin-bottom: 20px;
  }
  .modal-container{
    width: 30%;
  }
  #applicant-type-contener{
    width: 20%;
  }
  .modal-btn{
    font-size: x-large;
  }
  .fullapp{
    width: 50vh;
  }
  /* side pages */
  .form-contener{
    width: 45%;
    margin-top: 30vh;
    margin-bottom: 5vh;
  }
  #applicant-contener{
    margin-top: 10vh;
  }
  .input-box , .form-submitbtn{
    height: 7vh;
  }
  
}



